import request from '@/utils/request';
/**
 *  fetch: 获取
 *  update: 修改
 *  add: 增加
 *  del: 删除
 */
// 充值佣金账单

export function fetchBillList(data) {
  return request({
    url: '/commission_bill',
    method: 'get',
    params: data
  });
} // 充值佣金比例设置

export function updateSet(data) {
  return request({
    url: '/commission',
    method: 'post',
    data: data
  });
} // 获取佣金比例

export function fetchSet() {
  return request({
    url: '/commission',
    method: 'get'
  });
} // 充值结算

export function updateAccount(id) {
  return request({
    url: "/commission_bill/".concat(id),
    method: 'put'
  });
} // 充值账单明细

export function fetchAcDetail(id, params) {
  return request({
    url: "/commission_bill/".concat(id),
    method: 'get',
    params: params
  });
} // 营业额
// 营业额佣金账单

export function fetchTranoverList(data) {
  return request({
    url: '/performance_commission_bill',
    method: 'get',
    params: data
  });
} // 营业额明细

export function fetchTranoverDetail(data) {
  return request({
    url: "/performance_commission_bill/".concat(data.id),
    method: 'get',
    params: data
  });
} // 营业额账单结算

export function updateTranover(data) {
  return request({
    url: "/performance_commission_bill/".concat(data.id),
    method: 'put'
  });
} // 营业额佣金配置列表

export function fetchRateList(data) {
  return request({
    url: '/performance_commission',
    method: 'get',
    params: data
  });
} // 营业额佣金比例设置

export function updateRate(data) {
  return request({
    url: '/performance_commission',
    method: 'post',
    data: data
  });
} // 营业额账单列表

export function fetchTurnoverBillList(data) {
  return request({
    url: '/finance/turnover/bill',
    method: 'get',
    params: data
  });
}
export function fetchTurnoverBillDetail(bill_id) {
  return request({
    url: '/finance/turnover/bill/' + bill_id,
    method: 'get'
  });
}
export function fetchTurnoverBillOrderList(data) {
  return request({
    url: '/finance/turnover/bill/order_list/' + data.id,
    method: 'get',
    params: data
  });
}
export function fetchTurnoverBillRefundList(data) {
  return request({
    url: '/finance/turnover/bill/refund_list/' + data.id,
    method: 'get',
    params: data
  });
}
export function checkTurnoverBill(data) {
  return request({
    url: '/finance/turnover/bill/check',
    method: 'put',
    data: data
  });
}
export function getPayInfoTurnoverBill(bill_id) {
  return request({
    url: '/finance/turnover/bill/pay/' + bill_id,
    method: 'get'
  });
}
export function payTurnoverBill(data) {
  return request({
    url: '/finance/turnover/bill/pay',
    method: 'post',
    data: data
  });
} // 营业额
// 营业额收款人设置列表

export function fetchTurnoverPayeeList(data) {
  return request({
    url: '/finance/turnover/payee',
    method: 'get',
    params: data
  });
} // 添加收款人

export function addTurnoverPayee(data) {
  return request({
    url: '/finance/turnover/payee',
    method: 'post',
    data: data
  });
} // 编辑收款人

export function updateTurnoverPayee(data) {
  return request({
    url: "/finance/turnover/payee/".concat(data.id),
    method: 'put',
    data: data
  });
} // 删除收款人

export function delTurnoverPayee(id) {
  return request({
    url: "/finance/turnover/payee/".concat(id),
    method: 'delete'
  });
} // 创链账单列表

export function fetchInnochainSettleList(data) {
  return request({
    url: '/finance/innochain/settle',
    method: 'get',
    params: data
  });
}
export function fetchInnochainSettleDetailList(data) {
  return request({
    url: '/finance/innochain/settleDetailList',
    method: 'get',
    params: data
  });
} // 创链提现列表

export function fetchInnochainWithdrawalList(data) {
  return request({
    url: '/finance/innochain/withdrawal',
    method: 'get',
    params: data
  });
}
export function checkInnochainWithdrawal(data) {
  return request({
    url: '/finance/innochain/withdrawal/check',
    method: 'put',
    data: data
  });
} // 创链小区

export function fetchInnochainSettleProperty() {
  return request({
    url: '/finance/innochain/settleProperty',
    method: 'get'
  });
}
export function fetchCreditRecordList(data) {
  return request({
    url: '/finance/credit_record',
    method: 'get',
    params: data
  });
}
export function fetchBalanceRecordList(data) {
  return request({
    url: '/finance/balance_record',
    method: 'get',
    params: data
  });
}